import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#182B5E",
    },
    secondary: {
      main: "#707070",
    },
  },
  typography: {
    h6: {
      color: "#182B5E",
      fontWeight: 'bold'
    },
    subtitle2: {
      color: "#182B5E",
      fontWeight: 'bold',
    },
    body2: {
      color: "#707070",
      fontWeight: 'bold'
    },
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontSize: 17,
      fontWeight: 'bold'
    }
  },
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 15,
          backgroundColor: '#fff'
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        sx: {
          paddingTop: 4
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          fontSize: 15,
          backgroundColor: '#fff',
        }
      }
    }
  }
});

export default theme;
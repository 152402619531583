import axios from 'axios';

export const fetch = async props => {
  let data = null;
  let error = null;
  let loading = true;

  const config = {
    method: props.method,
    url: props.url, 
    data: props.method === 'post' && props.data,
    // headers: {'X-Requested-With': 'XMLHttpRequest'}
    headers: props.headers || {},
    params: props.method === 'get' && props.params,
    // auth: {
    //   username: 'janedoe',
    //   password: 's00pers3cret'
    // }user.uid
  }
  // console.log("cinfig in fetch", config)
  await axios(config)
  .then((response) => {
    data = response.data;
  })
  .catch((err) => {
    error = err;
  })
  .finally(()=> {
    loading = false;
  });
  
  return {data, error, loading};
}

// import {ISSUER, UTILS, HASH} from "@env";

const UTILS = "https://utils.unifid.io";
// const UTILS = "https://npd.utils.unifid.io";

export const  url = {
  // dev
  GET_CONTRACT_ADDRESS: `${UTILS}/getContractAddress`, // ?walletAddress=0x318F4541c36c138378dd8293d8b50ACd4178a19B
  GET_CLAIMS_BY_TOPIC: `${UTILS}/getClaimsByTopic`, // ?contractAddress=0xDFAc7e953786d52826Ae379209a680D84eE3d16f&claimType=5
  GET_CLAIM_DATA: `${UTILS}/getClaimData`, // ?contractAddress=0xDFAc7e953786d52826Ae379209a680D84eE3d16f&claimID=0x32b91df4b1d75f7fe5d111ad08d6ba4dfebbb86b56a37a8e74593f4cc29b5860`,
}
import React, { useState } from 'react';
import './App.css';

import Image from 'mui-image'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grow from '@mui/material/Grow';
import Tooltip from '@mui/material/Tooltip';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
// import PersonIcon from '@mui/icons-material/Person';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import DrawerAppBar from './components/Navbar'
import Searchbar from './components/Searchbar'

import BackgroundImage from "./assets/images/background.png";
import NoUpdates from "./assets/images/no_updates.png";

import theme from './theme'

// Utils
import {url} from './utils/constants/url';
import { fetch } from './utils/functions/fetch';
// import { getDate, getISODate } from './utils/functions/collection';

const Details = (props) => {
  return (<Grid container spacing={4} columns={12} direction="row" justifyContent="center">
    <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
      <Typography variant="h6" gutterBottom>
        Linked Identity
      </Typography>
      {
        props.isCredential
        ? <Grow
          in={props.isCredential}
          style={{ transitionDelay: props.isCredential ? '0ms' : '0ms' }}
        >
          <Box sx={{position: 'relative'}}>
            <Paper elevation={0} variant="outlined" sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 0.9}}>  
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
            </Paper>
          </Box>
        </Grow>
        : <Box sx={{position: 'relative'}}>
            <CheckCircleIcon sx={{position: 'absolute', right: 12, top: 12, color: '#5AB04E'}}/>
            <InfoIcon fontSize="10" sx={{position: 'absolute', right: 12, bottom: 12, color: '#797979'}}/>
            <Paper elevation={0} variant="outlined" sx={{padding: 2}}>
              <Typography variant="body2" gutterBottom>
                Linked Identity Source
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.credentialDetails?.identity_verification_document}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Identity Document Expiry Date
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.credentialDetails?.document_expiry_date}
              </Typography>
              <Typography variant="body2" gutterBottom>
                On chain timestamp
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.credentialDetails?.timestamp}
              </Typography>
              <Typography variant="body2" gutterBottom>
                On chain credential ID
              </Typography>
              <Tooltip title={props.credentialDetails?.claim_id} arrow>
                <Typography variant="subtitle2" gutterBottom sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {props.credentialDetails?.claim_id}
                </Typography>
              </Tooltip>
            </Paper>
          </Box>
      }
      <Typography variant="h6" gutterBottom sx={{marginTop: 3}}>
        AML/Sanctions
      </Typography>
      {
        props.isAml
        ? <Grow
          in={props.isAml}
          style={{ transitionDelay: props.isAml ? '0ms' : '0ms' }}
        >
          <Box sx={{position: 'relative'}}>
            <Paper elevation={0} variant="outlined" sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 1.21}}>  
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
              {/* <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography> */}
            </Paper>
          </Box>
        </Grow>
        : props.isAml === null 
          ? <Box>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                padding: 2,
                // marginTop: 3,
                height: 189.3,
                position: "relative",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <Image src={NoUpdates} width={'auto'} /> */}
              <Typography variant="h6" sx={{position: "absolute"}}>
                No information
              </Typography>
            </Paper>
          </Box>
          : <Box sx={{position: 'relative'}}>
            <WarningAmberRoundedIcon sx={{position: 'absolute', right: 12, top: 12, color: '#E2960A'}}/>
            <InfoIcon fontSize="10"  sx={{position: 'absolute', right: 12, bottom: 12, color: '#797979'}}/>
            <Paper elevation={0} variant="outlined" sx={{padding: 2}}>
              <Typography variant="body2" gutterBottom>
                AML Match found
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.amlDetails?.aML_status}
              </Typography>
              <Typography variant="body2" gutterBottom>
                AML Searched On
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.amlDetails?.aML_searched_on}
              </Typography>
              <Typography variant="body2" gutterBottom>
                On chain timestamp
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {props.amlDetails?.timestamp}
              </Typography>
              {/* <Typography variant="body2" gutterBottom>
                On chain credential ID
              </Typography>
              <Tooltip title="0xyh67860D46c0d4Cc890CB67Dd3C528ccD956ncd8" arrow>
                <Typography variant="subtitle2" gutterBottom sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  0xyh67860D46c0d4Cc890CB67Dd3C528ccD956ncd8
                </Typography>
              </Tooltip> */}
            </Paper>
          </Box>
      }

      {/* <Typography variant="h6" gutterBottom sx={{marginTop: 3}}>
        Linked Wallets
      </Typography>
      {
        props.claimIds.length <= 0
        ? <Box sx={{position: 'relative'}}>
          <InfoIcon fontSize="10" sx={{position: 'absolute', right: -25, top: 12, color: '#797979'}}/>
          <Paper elevation={0} variant="outlined" sx={{padding: 2}}>
            <Typography component="div" variant="body2">
              <Skeleton />
            </Typography>
            <Typography component="div" variant="subtitle2">
              <Skeleton />
            </Typography>
          </Paper>
        </Box>
        : <Box sx={{position: 'relative'}}>
          <InfoIcon fontSize="10" sx={{position: 'absolute', right: -25, top: 12, color: '#797979'}}/>
          <Paper elevation={0} variant="outlined" sx={{padding: 2}}>
            <Typography variant="body2" gutterBottom>
              Multiple Wallets
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {props.claimIds.length - 2 <= 0 ? "more wallets linked to this identity" : `${props.claimIds.length - 2} more wallets linked to this identity`}
            </Typography>
          </Paper>
        </Box>
      } */}
    </Grid>
    <Grid item xs={12} md={4} sx={{textAlign: 'left'}}>
      <Typography variant="h6" gutterBottom>
        Linked Wallets
      </Typography>
      {
        props.claimIds.length <= 0
        ? <Grow
          in={props.claimIds.length <= 0}
          style={{ transitionDelay: props.claimIds.length <= 0 ? '0ms' : '0ms' }}
        >
          <Box sx={{position: 'relative'}}>
            <Paper elevation={0} variant="outlined" sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 2, paddingBottom: 1.7}}>
              <Typography variant="h4" gutterBottom>
                <Skeleton animation="wave" />
              </Typography>
            </Paper>
          </Box>
        </Grow>
        : <Box sx={{position: 'relative'}}>
          <InfoIcon fontSize="10" sx={{position: 'absolute', right: 12, bottom: 12, color: '#797979'}}/>
          <Paper elevation={0} variant="outlined" sx={{padding: 2}}>
            <Typography variant="body2" gutterBottom>
              Multiple Wallets
            </Typography>
            {/* <Typography variant="subtitle2" gutterBottom>
              {props.claimIds.length - 2 <= 0 ? "more wallets linked to this identity" : `${props.claimIds.length - 2} more wallets linked to this identity`}
            </Typography> */}
            <Typography variant="subtitle2" gutterBottom>
              No additional wallets linked with this Identity
            </Typography>
          </Paper>
        </Box>
      }
    </Grid>
    {/* <Grid item xs={8} sx={{textAlign: 'left'}}>
      <Typography variant="h6" gutterBottom>
        Transactions
      </Typography>
      <Paper elevation={0} variant="outlined" sx={{padding: 2, minHeight: 500}}>
        <Grid container spacing={2} columns={16} sx={{marginBottom: 3}}>
          <Grid item xs={16}>
            <Grid container spacing={2} columns={16} sx={{marginBottom: 3}}>
              <Grid item xs={5} sx={{textAlign: 'left'}}>
                <Typography variant="subtitle2" gutterBottom>
                  0xb8d6bff0ca8c143bad178.......
                </Typography>
                <Typography variant="body2" gutterBottom>
                  2 days ago
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0x6377Ff804f2d098AEFf...
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      To
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0x2E34e29bdc9afea575B72...
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sx={{textAlign: 'left'}}>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Michael | UGA 
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Okaleke | KEN
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="subtitle2" gutterBottom>
                  0.567 Eth
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{marginBottom: 3}}>
              <Grid item xs={5} sx={{textAlign: 'left'}}>
                <Typography variant="subtitle2" gutterBottom>
                  0xcg6dhdyej74849383nd8......
                </Typography>
                <Typography variant="body2" gutterBottom>
                  6 days ago
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0x6377Ff804f2d098AEFf...
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      To
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0x2E34e29bdc9afea575B72...
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sx={{textAlign: 'left'}}>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Michael | UGA 
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Fanken | NGA 
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="subtitle2" gutterBottom>
                  120 Celo
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{marginBottom: 3}}>
              <Grid item xs={5} sx={{textAlign: 'left'}}>
                <Typography variant="subtitle2" gutterBottom>
                  0xcg6dhdyej74849383nd8.......
                </Typography>
                <Typography variant="body2" gutterBottom>
                  6 days ago
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0x6377Ff804f2d098AEFf...
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={2}>
                    <Typography variant="body2" gutterBottom>
                      To
                    </Typography>
                  </Grid>
                  <Grid item xs={14}>
                    <Typography variant="subtitle2" gutterBottom>
                      0xcBfa884044546d5569E2ab...
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sx={{textAlign: 'left'}}>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Michael | UGA 
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent:'start', alignItems: 'center'}}>
                  <PersonIcon fontSize="10" sx={{color: '#797979'}}/>
                  <Typography variant="subtitle2" sx={{margin: "4px 1px 0px 4px"}}>
                    Heather | ZAF 
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="subtitle2" gutterBottom>
                  95 USDC
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid> */}
  </Grid>)
}

function App() {
  const [searchString, setSearchString] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [showTimer, setshowTimer] = useState('')
  const [credentialDetails, setCredentialDetails] = useState({})
  const [amlDetails, setAmlDetails] = useState({})
  const [claimIds, setClaimIds] = useState([])
  const [isCredential, setIsCredential] = useState(true)
  const [isAml, setIsAml] = useState(true)

  const addZero = (value, _length) => {
    while (value.toString().length < _length) {
      value = "0" + value;
    }
    return value;
  }

  const getDate = (value) => {
    const date = new Date(value)
    const _date = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
    const _month = date.getUTCMonth()+1 < 10 ? `0${date.getUTCMonth()+1}` : date.getUTCMonth()+1;
    const selectedDate = `${_date}-${_month}-${date.getUTCFullYear()}`;
    return selectedDate;
  };

  const getTime = (value) => {
    const date = new Date(value)
    const selectedTime = `T${addZero(date.getUTCHours(), 2)}:${addZero(date.getUTCMinutes(), 2)}:${addZero(date.getUTCSeconds(), 2)}.${addZero(date.getUTCMilliseconds(), 3)}`;
    return selectedTime;
  };

  const resetValues = async () => {
    setIsValid(false)
    setshowTimer('')
    setCredentialDetails({})
    setAmlDetails({})
    setClaimIds([])
    setIsCredential(true)
    setIsAml(true)
  }

  const handleWalletAddress = async (e) => {
    // console.log("on Handle click", searchString)
    await resetValues()
    // if(searchString === '0x14b1e364A6487B66E6667d06C405d5C050c46637') {
    //   setIsValid(true)
    // } else {
    //   setIsValid(false)
    //   setshowTimer('yes')
    //   setTimeout(() => {
    //     setshowTimer('no')
    //   }, [8000])
    // }
    await fetchContractAddress()
  }

  const fetchContractAddress = async () => {
    const config = {
      method: 'get',
      url: url.GET_CONTRACT_ADDRESS,
      params: {
        walletAddress: searchString
      }
    }
    try {
      await fetch(config)
      .then(async res => {
        // console.log("res fetchContractAddress", res.data)
        if(res.data.length > 0) {
          setIsValid(true)
          await fetchClaimsByTopic(res.data[0])
        }
        else {
          setIsValid(false)
          setshowTimer('yes')
          // setTimeout(() => {
          //   setshowTimer('no')
          // }, [8000])
        }
      })
      .catch(err => {
        console.error("err", err);
      })
      // .finally(async () => {   
      // })
    } catch (e) {
      console.error(e);
    }
  };

  const fetchClaimsByTopic = async (contractAddress) => {
    const config = {
      method: 'get',
      url: url.GET_CLAIMS_BY_TOPIC,
      params: {
        contractAddress: contractAddress,
        claimType: 5
      }
    }
    try {
      await fetch(config)
      .then(res => {
        // console.log("res fetchClaimsByTopic", res.data.claimIds)
        setTimeout(() => {
          setClaimIds(res.data.claimIds)
        }, 2500);
        if (res.data.claimIds.length === 1) setTimeout(() => { setIsAml(null) }, 1500);
        res.data?.claimIds.map((key, idx) => {
          if(idx === 2){
            return true
          } else {
            fetchClaimData(contractAddress, key)
            return null;
          }
        })
      })
      .catch(err => {
        console.error("err", err);
      })
      // .finally(() => {    
      // })
    } catch (e) {
      console.error(e);
    }
  };

  const fetchClaimData = async (contractAddress, claimID) => {
    const config = {
      method: 'get',
      url: url.GET_CLAIM_DATA,
      params: {
        contractAddress: contractAddress,
        claimID: claimID
      }
    }
    try {
      await fetch(config)
      .then(async res => {
        // console.log("res fetchClaimData", res.data)
        Object.keys(res.data.claimData.data).every(key => {
          // console.log("key outside", key)
          if(key === "identity_verification_document") {
            setCredentialDetails({
              ...res.data.claimData.data,
              claim_id: res.data.claimData.claim_id,
              timestamp: `${getDate(res.data.timestamp)}${getTime(res.data.timestamp)}`
            })
            setTimeout(() => {
              setIsCredential(false)
            }, 1000);
            return false;
          } else if(key === "aML_status") {
            setAmlDetails({
              aML_searched_on: getDate(res.data.claimData.data.aML_searched_on),
              aML_status:res.data.claimData.data.aML_status,
              timestamp: `${getDate(res.data.timestamp)}${getTime(res.data.timestamp)}`
            })
            setTimeout(() => {
              setIsAml(false)
            }, 1500);
            return false;
          }
        });
      })
      .catch(err => {
        console.error("err", err);
      })
      // .finally(async () => {   
      // })
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="App"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        // marginTop: '-2.5rem',
        backgroundSize: '100%, 80%',
        backgroundPosition: '80% 31%',
        minHeight: "100vh"
      }}
    >
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" sx={{marginBottom: 5}}>
          <DrawerAppBar />
          <Grid container wrap="nowrap" spacing={2} columns={12} sx={{justifyContent: 'center', marginBottom: 3, marginTop: {md: "100px"}}}>
            <Grid item xs={12} md={8}>
              <Searchbar handleWalletAddress={handleWalletAddress} searchString={searchString} setSearchString={setSearchString}/>
            </Grid>
          </Grid>
          {
            isValid
            && <Details credentialDetails={credentialDetails} amlDetails={amlDetails} claimIds={claimIds} isCredential={isCredential} isAml={isAml} />
          }
          {
            !isValid && showTimer === 'yes'
            && <Grid container spacing={4} columns={12} direction="row" justifyContent="center">
              <Grid item xs={12} md={8}>
                <Paper elevation={0} variant="outlined" sx={{position: "relative", minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Image src={NoUpdates} width={500} />
                  <Typography variant="h6" sx={{position: "absolute", left: 0, bottom: '30%', right: 0}}>
                    No information
                  </Typography>
                  {/* <Skeleton variant="rectangular" animation="wave" sx={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/> */}
                  {/* <Skeleton variant="rectangular" height={207} animation="wave" /> */}
                </Paper>
              </Grid>
            </Grid>
          }
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React, { useState, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

export default function Searchbar(props) {
  const [addressType, setAddressType] = useState('walletAddress');

  const handleAddressType = (event) => {
    setAddressType(event.target.value);
  };

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom sx={{textAlign: 'left'}}>
        Wallet &lt; &gt; Identity Explorer
      </Typography>
      <TextField
        size="default"
        label=""
        placeholder="Enter Wallet Address"
        fullWidth
        sx={{div: {paddingRight: 0, paddingLeft: 0, overflow: 'hidden', textOverflow: 'ellipsis'}}}
        value={props.searchString}
        onChange={(event) => props.setSearchString(event.target.value)}
        InputProps={{
          startAdornment: <TextField
            size="small"
            sx={{
              width: 250,
              marginRight: 1,
              "& .Mui-focused": {
                backgroundColor: 'transparent !important'
              },
              "& .MuiFilledInput-root:before, & .MuiFilledInput-root:after, & .MuiFilledInput-root:hover:before, & .MuiFilledInput-root:hover, & .MuiInputBase-inputSizeSmall:focus": {
                borderBottom: 'none !important',
                backgroundColor: 'transparent !important'
              },
              "& .MuiSelect-filled": {
                paddingTop: '4px !important'
              },
              "& .MuiInputBase-inputSizeSmall": {
                paddingRight: {md: '10px !important'},
                paddingLeft: {xs: '10px !important', md: '0px !important'}
              }
            }}
            name="cls"
            select
            value={addressType}
            // margin="normal"
            variant="filled"
            onChange={handleAddressType}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            <MenuItem value='walletAddress'>Wallet Address</MenuItem>
            <MenuItem value='identityAddress'>Identity Address</MenuItem>
          </TextField>,
          endAdornment: <Button
            variant="contained"
            style={{
              height: 56,
              // marginTop: 1
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              paddingLeft: 35,
              paddingRight: 35
            }}
            onClick={props.handleWalletAddress}
            endIcon={<EastOutlinedIcon />}
          >
            Search
          </Button>
        }}
      />
    </Fragment>
  );
}